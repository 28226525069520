







































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {},
})
export default class TasqItem extends Vue {
  @Prop({ type: String, required: true }) tasqId!: string;
  @Prop({ type: Boolean, required: false }) tasqCompleted?: string;

  get tasq(): TasqJob|undefined {
    return tasqsListModule.tasqById(this.tasqId);
  }

  get isTasqActive(): boolean {
    return this.tasq?.id === this.activeTasq?.id;
  }

  get activeTasq() {
    return tasqsListModule.activeTasq;
  }

  fullName() {
    if (this.tasq?.reassignedUserName) {
      const initialUserName = getNameByEmail(this.tasq.username).split(' ');
      const reassignedUsername = getNameByEmail(this.tasq.reassignedUserName).split(' ');
      return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
    }
	  return getNameByEmail(this.tasq?.username);
  }

  async created() {
	  
	  accountModule.getReassignmentList()
  }

  goToTasqPage() {
    this.$router.push({
      name: 'Tasqs',
      params: {
        id: this.tasq?.id || '',
      },
    });
    tasqsListModule.setActiveTasq(this.tasq?.id || '');
  }
}
